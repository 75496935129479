.wrapper{
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 10px 0 rgb(190, 190, 190);
    margin-bottom: 20px;
}

.container{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content{
    width: 92%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.product {
    display: flex;
    width: 45%;
    height: 100%;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.image{
    width: 130px;
    height: 130px;
    display: flex;
}
.info{
    padding-left: 15px;
    width: 72%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.name {
    width: auto;
    height: auto;
    font-size: 1.1rem;
    font-weight: 700;
    color: #4d4d4d;
    word-wrap: break-word; 
}

.description {
    width: 340px;
    height: auto;
    font-size: 0.9rem;
    color: #4d4d4d;
    white-space: normal; 
    overflow: visible; 
} 

.quantity{
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}
.price{
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.date{
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}