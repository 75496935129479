.wrapper {
    display: flex;
    width: 100%;
    height: 300px;
    margin-top: 50px;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    background-color: #f0f0f0;
    border-radius: 30px;
}

.box-footer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 250px;
    justify-content: center;
    align-items: center;
}

.image-box-footer {
    display: flex;
    width: 80px;
    height: 80px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    background: var(--color-primary);
    cursor: pointer;
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0px 0px 10px 0px #667bb3;

        .text-box-footer-title {
            transform: scale(1.5);
            transition: transform 0.5s ease-in-out;
        }
    }
}

.text-box-footer {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text-box-footer-title {
    font-size: 25px;
    font-weight: 600;
    color: #000000;
    margin-top: 10px;
}

.text-box-footer-content {
    font-size: 15px;
    font-weight: 300;
    color: #000000;
    margin-top: 5px;
}
