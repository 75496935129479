.wrapper{
    display: flex;
    height: auto;
    padding-top: 60px;
    margin-bottom: 100px;
    width: var(--default-layout-header-width);
    justify-content: space-between;
    user-select: none;
}

.inner{
    flex-direction: column;
    width: 68%;
    height: 100%;
}

.header{
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 700;
}

.body{
    height: auto;
    min-height: 600px;
    width: 100%;
    display: flex;
    background-color: #f5f5f5;
    border-radius: 30px;
    padding: 50px;

    form {
        width: 100%;
    }
}
.sidebar{
    margin-top: 80px;
    display: flex;
    width: 30%;
    height: 600px;
    background-color: #f5f5f5;
    border-radius: 30px;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.avatar{
    display: flex;
}

.avatar-img{
    width: 300px;
    height: 300px;
    border-radius: 50%;
}

.avatar-img img{
    width: 100%;
    height: auto;
    border-radius: 50%;
}
.body-sidebar{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
}

.body-sidebar-name{
    font-size: 30px;
    font-weight: 700;
    margin-top: 20px;
}

.body-sidebar-user{
    font-size: 20px;
    font-weight: 300;
}

.button-sidebar{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    background-color: #dbdbdb;
    border-radius: 30px;
    margin-top: 35px;
    cursor: pointer;
    color: #000;
    transition: 0.5s; 

    font-size: 15px;
    font-weight: 500;

    &:hover{
        background-color: #002ba0;
        color: #f5f5f5;
        transition: 0.5s;
        box-shadow: 0 0 10px #002ba0;
    }

    &:active{
        background-color: #3665e6;
        color: #f5f5f5;
        transition: 0.5s;
        box-shadow: 0 0 10px #002ba0;
        transform: scale(0.98);
        transition: transform 0.2s;
    }
}

.button-sidebar-active {
    background-color: #3665e6;
    color: #f5f5f5;
    box-shadow: 0 0 10px #002ba0;

    &:hover {
        background-color: #002ba0;
        color: #f5f5f5;
    }
}

.user-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 30px;

    .row {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
        width: 100%;
    }
}

.user-info-tag{
    width: 100%;
    flex: 1;
    min-width: 0;
}

.user-info-title{
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 5px;
}

.user-info-content{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5s;
    border: 1px solid #dbdbdb;
    font-size: 20px;
    font-weight: 300;
    height: 55px;

    &:hover{
        box-shadow: 0 0 10px #bbbbbb;
        color: #ffffff;
        transition: 0.5s;
    }

    input {
        width: 100%;
    }

    input,
    select {
        width: 100%;
        padding: 12px 15px;
        border: none;
        outline: none;
        background: transparent;
        font-size: 18px;
        color: #333;
        border-radius: 10px;

        &:disabled {
            cursor: not-allowed;
            color: #666;
        }

        &:focus {
            background-color: rgba(0, 43, 160, 0.05);
        }
    }

    select {
        cursor: pointer;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 15px center;
        background-size: 15px;
        padding-right: 45px;
    }

    &:hover {
        box-shadow: 0 0 10px rgba(0, 43, 160, 0.2);
        border-color: #002ba0;
        background-color: #fff;
        color: #333;
    }
}

.popup-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	background-color: #f5f5f5;
	border-bottom: 1px solid #ddd;

	h2 {
		color: #333;
		margin: 0;
		font-size: 1.2rem;
	}
}

.close-btn {
	background: none;
	border: none;
	font-size: 24px;
	color: #000000;
	cursor: pointer;

	&:hover {
		box-shadow: #000000;
		background-color: #f5f5f5;
	}
}

.image {
	display: flex;
	width: 100%;
	height: 100px;
	background-size: auto;
	background-position: auto;
	justify-content: center;
	align-items: center;
}

.popup-form {
	padding: 16px;
	height: auto;
	text-align: center;
	min-height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 1.2rem;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;

  img {
    width: 50px;
    height: 50px;
  }
}