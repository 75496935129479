.wrapper {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
	height: auto;
	width: var(--default-layout-header-width);
	align-items: flex-start;
	user-select: none;
	margin-bottom: 20px;
}

.inner {
	width: 68%;
	display: flex;
	flex-direction: column;
	border-radius: 30px;
	user-select: none;
}

.inner-tag {
	width: 100%;
	height: auto;
	background-color: var(--color-white);
	border-radius: 30px;
	padding: 40px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	margin-bottom: 30px;
}

.inner-tag-title {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 20px;
}

.inner-tag-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.inner-tag-content-item {
	display: flex;
	width: 100%;
	flex-direction: column;
	padding-bottom: 5px;
	margin-bottom: 8px;
}

.inner-tag-content-item-title {
	width: 100%;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 5px;
}

.inner-tag-content-item-input {
	width: 100%;
	height: 40px;
	display: flex;
	border: 1px solid #dbdbdb;
	border-radius: 5px;
	padding: 10px;
	justify-content: center;
	align-items: center;
	color: #333333;
	font-size: 20px;
	transition: border 0.3s ease, box-shadow 0.3s ease;

	input {
		width: 100%;
		height: 100%;
		border: none;
		outline: none;
		font-size: 15px;
		font-weight: 300;
		color: #333333;
	}

	&:hover {
		border: 1px solid #002ba0;
		box-shadow: 0 0 3px 0 #002ba0;
		transition: border 0.3s ease, box-shadow 0.3s ease;
	}

	&:focus-within {
		border: 1px solid #002ba0;
	}
}
.inner-tag-content-item-payment {
	display: flex;
	width: 100%;
	height: auto;
	flex-direction: column;

	gap: 20px;
}

.inner-tag-content-item-payment-tag {
	display: flex;
	width: 100%;
	height: 60px;
	padding: 10px;
	flex-direction: row;
	align-items: center;

	input {
		width: 20px;
		height: 20px;
		cursor: pointer;
	}

	img {
		width: 40px;
		height: 40px;
		margin-left: 20px;
	}
}

.inner-tag-content-item-payment-tag.selected {
	background-color: #f0f0f0;
	border-radius: 10px;
}

.inner-tag-content-item-payment-title {
	font-size: 16px;
	font-weight: 300;
	margin-left: 10px;
}

.sidebar {
	width: 30%;
	height: auto;
	background-color: var(--color-white);
	border-radius: 30px;
	padding-top: 40px;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 30px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.sidebar-title {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 20px;
}

.sidebar-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.item-title {
	display: flex;
	width: 100%;
	border-bottom: 1px solid #999999;
	justify-content: space-between;
	padding-bottom: 5px;
	margin-bottom: 15px;
}
.item-title-first {
	font-size: 16px;
	font-weight: 600;
	margin-right: 10px;
}

.item-title-second {
	font-size: 16px;
	font-weight: 600;
}

.item-content {
	display: flex;
	width: 100%;
	flex-direction: column;
}

.item-content-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.item-content-first {
	font-size: 16px;
	font-weight: 300;
	margin-right: 10px;
}

.item-content-second {
	font-size: 16px;
	font-weight: 300;
}

.item-total-first {
	font-size: 16px;
	font-weight: 300;
	margin-right: 10px;
}

.item-total-second {
	font-size: 25px;
	font-weight: 600;
	color: #dc2626;
}

.item-button {
	width: 100%;
	height: 50px;
	background-color: #002ba0;
	border: none;
	margin-top: 20px;
	border-radius: 30px;
	color: var(--color-white);
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	transition: background-color 0.3s ease, transform 0.2s ease-in-out;
	color: #ffffff;

	&:hover {
		background-color: #001e7d;
		transition: background-color 0.3s ease;
	}

	&:active {
		transform: scale(0.99);
		transition: 0.2s ease-in-out;
		background-color: #001e7d;
	}
}

.item-content-column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.item-content-description {
	margin-top: 5px;
	font-size: 12px;
	font-weight: 300;
	justify-content: center;
	width: 100%;
	text-align: center;

	p {
		display: inline;
		font-weight: 600;
	}
}

.item-content-description-link {
	margin-top: 5px;
	font-size: 12px;
	font-weight: 300;
	justify-content: center;
	width: 100%;
	text-align: center;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.product-item {
	margin-bottom: 15px;
}

.product-name {
	font-size: 16px;
	font-weight: 600;
}

.product-quantity,
.product-price {
	font-size: 14px;
	font-weight: 400;
}

.product-item {
	display: flex;
	align-items: center;
	padding: 15px;
	border-bottom: 1px solid #eee;

	&:last-child {
		border-bottom: none;
	}
}

.product-thumbnail {
	width: 80px;
	height: 80px;
	margin-right: 20px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 8px;
	}
}

.product-details {
	flex: 1;
}

.product-name {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 5px;
}

.product-quantity,
.product-price {
	font-size: 14px;
	color: #666;
	margin-bottom: 3px;
}

.voucher-button {
	width: 100%;
	padding: 12px;
	background-color: #f5f5f5;
	border: 1px solid #ddd;
	border-radius: 8px;
	cursor: pointer;
	font-size: 14px;
	transition: all 0.3s ease;

	&:hover {
		background-color: #eee;
		border-color: #ccc;
	}
}

.header-popup {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	background-color: #f5f5f5;
	border-bottom: 1px solid #ddd;

	h2 {
		margin: 0;
		font-size: 18px;
		color: #333;
	}
}

.close-btn-popup {
	background: none;
	border: none;
	font-size: 24px;
	cursor: pointer;
	color: #666;

	&:hover {
		color: #333;
	}
}

.content-popup {
	padding: 20px;
}

.voucher-input-container {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.voucher-input {
	width: 100%;
	padding: 12px;
	border: 1px solid #ddd;
	border-radius: 8px;
	font-size: 14px;

	&:focus {
		outline: none;
		border-color: #002ba0;
	}
}

.apply-voucher-btn {
	width: 30%;
	padding: 12px;
	background-color: #002ba0;
	color: white;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: #001e7d;
	}
}

.voucher-error {
	color: #dc2626;
	font-size: 14px;
	margin-top: 5px;
}

.applied-voucher {
	margin-top: 10px;
	padding: 8px;
	background-color: #e6f3ff;
	border-radius: 8px;
	font-size: 14px;
	color: #002ba0;
}

.voucher-section {
	margin: 15px 0;
	width: 100%;
}

.voucher-input-wrapper {
	display: flex;
	gap: 10px;
	margin-bottom: 10px;
}

.voucher-input {
	flex: 1;
	padding: 8px 12px;
	border: 1px solid #ddd;
	border-radius: 8px;
	font-size: 14px;

	&:focus {
		outline: none;
		border-color: #002ba0;
	}
}

.apply-voucher-btn {
	padding: 8px 16px;
	background-color: #002ba0;
	color: white;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: #001e7d;
	}
}

.voucher-error {
	color: #dc2626;
	font-size: 14px;
	margin-top: 5px;
}

.applied-voucher {
	margin-top: 10px;
	padding: 8px;
	background-color: #e6f3ff;
	border-radius: 8px;
	font-size: 14px;
	color: #002ba0;
}

.remove-voucher {
	margin-left: 8px;
	background: none;
	border: none;
	color: red;
	cursor: pointer;
	font-size: 16px;
	padding: 0 4px;

	&:hover {
		background-color: rgba(255, 0, 0, 0.1);
		border-radius: 50%;
	}
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	background-color: #f5f5f5;
	border-bottom: 1px solid #ddd;

	h2 {
		color: #333;
		margin: 0;
		font-size: 1.2rem;
	}
}

.close-btn {
	background: none;
	border: none;
	font-size: 24px;
	color: #000000;
	cursor: pointer;

	&:hover {
		background-color: #f5f5f5;
	}
}

.content {
	padding: 16px;
	text-align: center;

	p {
		margin: 0;
		font-size: 1.1rem;
		color: #333;
	}
}

.loading {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;

	img {
		width: 50px;
		height: 50px;
	}
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;

  img {
    width: 50px;
    height: 50px;
  }
}