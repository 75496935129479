.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.background{
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    background: no-repeat center/cover;
    mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 80%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 80%);
}

.container{
    z-index: 1;
    flex-direction: column;
    width: 100%;
}

.loading-body {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 100;
}