.wrapper {
    margin-top: 40px;
    height: auto;
    width: 100%;
    user-select: none;
}

.banner-body {
    display: flex;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    
    &:hover .control-button {
        opacity: 1; 
        transition: opacity 0.3s ease-in-out; 
    }
}

.banner-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    transition: transform 0.5s ease-in-out; 
}

.banner-container img {
    width: 100%;
    height: 500px;
    object-fit: contain;
    flex-shrink: 0;
}

.image-banner {
    padding: 50px;
    display: flex;
    width: 100%;
    height: auto;
    cursor: pointer;
}

.controls {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.control-button {
    box-shadow: 0 0 10px rgb(80, 80, 80);
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 20px;
    border-radius: 50%;
    opacity: 0;
    background-color: #fff;
    transition: opacity 0.5s ease, transform 0.5s ease; 

    .banner-body:hover & {
        opacity: 1;
        pointer-events: auto;
        transition: opacity 0.5s ease, transform 0.5s ease; 
    }

    &:hover {
        transform: scale(1.1); 
        background-color: #fff;
        transition: transform 0.5s ease; 
    }

    &:active {
        transform: scale(0.9); 
        transition: transform 0.5s ease; 
    }
}

.banner-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: auto;
    margin-top: 35px;
    background-color: #f0f0f0;
    border-radius: 0 0 30px 30px;
}

.thumbnail {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 60px;
    height: 70px;
    cursor: pointer;
    margin: 0 5px;
    opacity: 0.3;
    border-radius: 10px;
    background-color: #d6d6d6;
    transition: opacity 0.3s ease;
}

.thumbnail.active {
    opacity: 1;
    background-color: #f0f0f0;
    border: 2px solid #002ba0;
}