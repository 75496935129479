.wrapper {
	width: var(--default-layout-header-width);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 91vh;
}

.container {
	background-color: #fff;
	border-radius: 30px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
	position: relative;
	overflow: hidden;
	width: 1000px;
	max-width: 100%;
	min-height: 550px;
	user-select: none;
}

.container p {
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.3px;
	margin: 20px 0;
}

.container span {
	font-size: 12px;
}

.container a {
	color: #333;
	font-size: 13px;
	text-decoration: none;
	margin: 15px 0 10px;
}

.container button {
	background-color: #354bc9;
	color: #fff;
	font-size: 12px;
	padding: 10px 45px;
	border: 1px solid transparent;
	border-radius: 8px;
	font-weight: 600;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	margin-top: 10px;
	cursor: pointer;
}

.container button.hidden {
	background-color: transparent;
	border-color: #fff;
	font-size: 15px;
}

.container form {
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	height: 100%;
}
.text-button {
	font-size: 16px;
	cursor: pointer;
}

.forget-password,
.icon {
	font-size: 15px;
	cursor: pointer;
}

.forget-password:hover {
	text-decoration: underline;
}

.icon {
	display: flex;
	height: 40px;
	width: 40px;
}

.container input {
	background-color: #eee;
	border: none;
	margin: 8px 0;
	padding: 10px 15px;
	font-size: 16px;
	border-radius: 8px;
	width: 100%;
	outline: none;
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in {
	left: 0;
	width: 50%;
	z-index: 2;
}

.container.active .sign-in {
	transform: translateX(100%);
}

.sign-up {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.active .sign-up {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: move 0.6s;
}

@keyframes move {
	0%,
	49.99% {
		opacity: 0;
		z-index: 1;
	}
	50%,
	100% {
		opacity: 1;
		z-index: 5;
	}
}

.social-icons {
	margin: 20px 0;
	width: 110px;
	height: 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.toggle-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: all 0.6s ease-in-out;
	border-radius: 150px 0 0 100px;
	z-index: 1000;
}

.container.active .toggle-container {
	transform: translateX(-100%);
	border-radius: 0 150px 100px 0;
}

.toggle {
	background-color: var(--primary-color);
	height: 100%;
	background: linear-gradient(to right, #03157a, #40cdf8);
	color: #fff;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
	transform: translateX(0);
	transition: all 0.6s ease-in-out;
}

.container.active .toggle {
	transform: translateX(50%);
}

.toggle-panel {
	position: absolute;
	width: 50%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 30px;
	text-align: center;
	top: 0;
	transform: translateX(0);
	transition: all 0.6s ease-in-out;
}

.toggle-left {
	transform: translateX(-200%);
}

.container.active .toggle-left {
	transform: translateX(0);
}

.toggle-right {
	right: 0;
	transform: translateX(0);
}

.container.active .toggle-right {
	transform: translateX(200%);
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	background-color: #f5f5f5;
	border-bottom: 1px solid #ddd;

	h2 {
		color: #333;
		margin: 0;
		font-size: 1.2rem;
	}
}

.close-btn {
	background: none;
	border: none;
	font-size: 24px;
	color: #000000;
	cursor: pointer;

	&:hover {
		box-shadow: #000000;
		background-color: #f5f5f5;
	}
}

.image {
	display: flex;
	width: 100%;
	height: 100px;
	background-size: auto;
	background-position: auto;
	justify-content: center;
	align-items: center;
}

.content {
	padding: 16px;
	height: auto;
	text-align: center;
	min-height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 1.2rem;
}

.forgot-password-buttons {
	display: flex;
	gap: 20px;
	justify-content: space-between; 
	width: 100%;
	margin-top: 20px;

	button {
		flex: 1;
		min-width: 120px;
		max-width: none;
		
		&:first-child {
			background-color: #666;
			
			&:hover {
				background-color: #555;
			}
		}

		.text-button {
			white-space: nowrap;
		}
	}
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;

  img {
    width: 50px;
    height: 50px;
  }
}