.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; 
    width: 100%;
    height: auto;
    border-radius: 10px;
    user-select: none;
}

.brand-title {
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    color: #333;
    user-select: none;
    margin-bottom: 20px;
}

.brand-list {
    display: flex;
    flex-wrap: wrap; 
    width: 100%;
    height: auto;
    gap: 16px;
}

.brand-item {
    display: flex;
    align-items: center;
    width: 220px;
    height: 100px;
    background-color: #f0f0f0;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s;

    &:active {
        scale: 0.98;
        transition: scale 0.1s;
    }

    &.active {
        background-color: #e0e0e0;
        scale: 1.02;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    &:hover:not(.active) {
        background-color: #e0e0e0;
        scale: 1.02;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
}

.brand-item:hover {
    background-color: #e0e0e0;
    transition: 0.3s;
    scale: 1.02;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.brand-image {
    margin-left: 10px;
    width: 80px; 
    height: 80px; 
    object-fit: cover;
}

.brand-name {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}