.wrapper {
    width: var(--default-layout-header-width);
    display: flex;
    justify-content: center;
    user-select: none;
    height: auto;
}

.container {
    width: 100%;
    height: auto;
    display: flex;
    min-height: 91vh;
}

.temp{
    width: 100%;
    height: auto;
    flex-direction: column;
}

.inner{
    width: auto;
    background-color: #f5f5f5;
    height: auto;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    margin-top: 50px;
    margin-bottom: 30px;
    box-shadow: 0 0 10px 0 rgb(190, 190, 190);
    border-radius: 30px;
}

.empty-cart{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    font-size: 1.5rem;
    font-weight: 300;
    color: #4d4d4d;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    font-size: 16px;
    color: #666;
    border: 1px dashed #ccc;
    border-radius: 8px;
    margin: 20px 0;
}


.header{
    width: 83%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    justify-content: space-between;
    margin-left: 31px;
}
.name,
.title{
    font-size: 1.1rem;
    font-weight: 700;
    color: #4d4d4d;
}

.name{
    margin-right: 350px;
}

.content{
    height: auto;
    width: 90%;
    margin-bottom: 30px;
}

.checkout-temp,
.total,
.checkout,
.method-payment {
    display: none;
}

.header-popup {
    width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	background-color: #f5f5f5;
	border-bottom: 1px solid #ddd;

	h2 {
		color: #333;
		margin: 0;
		font-size: 1.2rem;
	}
}

.close-btn-popup {
	background: none;
	border: none;
	font-size: 24px;
	color: #000000;
	cursor: pointer;

	&:hover {
		box-shadow: #000000;
		background-color: #f5f5f5;
	}
}

.image {
	display: flex;
	width: 100%;
	height: 100px;
	background-size: auto;
	background-position: auto;
	justify-content: center;
	align-items: center;
}

.pic {
	width: 100px;
	height: 100%;
}

.content-popup {
    width: 100%;
	padding: 16px;
	height: auto;
	text-align: center;
	min-height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 1.2rem;
}