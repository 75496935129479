.wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
}

.name{
    margin-top: 15px;
    margin-left: 20px;
    font-size: 1.1rem;
    font-weight: 400;
}
.img{
    margin-top: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: scale(1);
    transition: transform 0.5s;
}
.img img{
    width: 150px;
    height: 150px;
}

.img:hover{
    transform: scale(1.03);
    transition: transform 0.5s;
}