.wrapper {
    display: flex;
    width: var(--default-layout-header-width);
    height: auto;
    min-height: 91vh;
    margin-top: 50px;
    margin-bottom: 50px;
    user-select: none;
    flex-direction: column;
    align-items: center;
}

.container {
    height: auto;
    width: 100%;
    justify-content: space-between;
    display: flex;
}

.content {
    height: auto;
    width: 62%;
    display: flex;
    flex-direction: column;
}

.img-content {
    display: flex;
    height: auto;
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 30px;
    padding-bottom: 45px;
}

.description {
    position: relative;
    width: 100%;
    height: 470px; 
    overflow: hidden;
    transition: height 0.5s ease; 
}

.description::after {
    content: ""; 
    position: absolute; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    height: 200px; 
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    pointer-events: none; 
    transition: background 0.5s ease;
}

.description.expanded {
    height: auto;
}

.description.expanded::after {
    height: 0;
    background: transparent;
}

.title-product-description {
    font-size: 15px;
    font-weight: 300;
    color: #000000;
}

.title-description {
    margin-left: 5px;
    font-size: 20px;
    font-weight: 300;
    color: #000000;
}


.name-description{
    margin-top: 50px;
}

.description-temp{
    border: 1px solid #888888;
    border-radius: 20px;
    margin-top: 10px;
}

.button-information {
    margin-top: 20px;
    margin-bottom: 20px;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.button-information-title{
    display: flex;
    height: 60px;
    width: 200px;
    background-color: #f0f0f0;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    color: #000000;
    transition: 0.3s ease;

    &:hover{
        transition: 0.3s ease;
        background-color: #4677ff;
        color: white;
        box-shadow: 0px 0px 8px 0px #a9c0ff;
    }
}

.sidebar {
    height: auto;
    width: 35%;
    padding-top: 20px;
}

.banner-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background-color: #f0f0f0;
    border-radius: 0 0 30px 30px;
}

.name-product {
    font-size: 30px;
    font-weight: 700;
    color: #000000;
}

.line-1 {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.model-product {
    font-size: 15px;
    font-weight: 200;
    color: #000000;
    padding-right: 20px;
    border-right: 1px solid #888888;
}

.rate-product {
    padding-left: 20px;
    font-size: 0;
    width: auto;
    height: auto;
    padding-right: 20px;
    border-right: 1px solid #888888;
}

.icon-star {
    width: auto;
    height: auto;
}

.comment-number {
    font-size: 15px;
    font-weight: 200;
    color: #000000;
    padding-left: 20px;
}

.line-2 {
    margin-top: 40px;
}

.line-3 {
    margin-top: 15px;
}

.line-2,
.line-3 {
    flex-direction: column;
    align-items: center;
}

.storage-product,
.color-product {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    padding-right: 20px;
}

.storage-option,
.color-option {
    margin-top: 10px;
    display: flex;
    font-size: 15px;
    font-weight: 200;
    color: #000000;
}

.storage-select,
.color-select {
    display: flex;
    padding-top: 2px;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 70px;
    width: auto;
    height: 45px;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    margin-right: 20px;
    border-radius: 15px;
    transition: 0.3s;
    cursor: pointer;
}

.storage-select:hover,
.color-select:hover {
    background-color: #4677ff;
    color: white;
    box-shadow: 0px 0px 8px 0px #a9c0ff;
}

.selected {
    background-color: #002ba0;
    color: white;
    box-shadow: 0px 0px 8px 0px #002ba0;

    &:hover {
        background-color: #002ba0;
        color: white;
        box-shadow: 0px 0px 8px 0px #002ba0;
    }
}

.line-4{
    margin-top: 20px;
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover{
        box-shadow: 0px 0px 20px 0px #667bb3;
        transition: 0.3s ease;

        .betwen-line {
            height: 90%;
            transition: 0.5s ease-in-out;
        }
    }
}

.price-product{
    display: flex;
    width: 100%;
    height: 200px;
    background: var(--color-primary);
    border-radius: 20px;
    color: white;
    align-items: center;
}

.price-current{
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 80%;
}

.betwen-line{
    display: flex;
    flex-direction: column;
    width: 8%;
    height: 75%;
    transition: 0.5s ease-in-out;
    justify-content: center;
    align-items: center;
}

.betwen-line-content{
    width: 100%;
    padding-top: 5px;
    padding-bottom: 4px;
    justify-content: center;
    font-size: 13px;
    font-weight: 200;
}

.price-title{
    font-size: 15px;
    font-weight: 200;
    color: white;
    margin-bottom: 5px;
}
.price-value{
    font-size: 30px;
    font-weight: 600;
    color: white;
}

.line-betwen{
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}

.line-5{
    margin-top: 40px;
    flex-direction: column;
    display: flex;
    height: auto;
    width: 100%;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid #888888;
}

.title-gift{
    display: flex;
    width: 100%;
    height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 20px;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom: 1px solid #888888;
    color: white;
    background: var(--color-primary);
}

.content-gift{
    display: flex;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.gift-img-content{
    display: flex;
    padding-right: 15px;
}

.gift-text-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 15px;
    font-weight: 300;
}

.footer-product{
    width: 100%;
    height: auto;
    display: flex;
}

.line-6{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.button-buy,
.button-installment,
.button-cart{
    background-color: #f0f0f0;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 80px;
    position: relative;
    margin-bottom: 10px;
    border-radius: 40px;
    overflow: hidden;
    transition: color 0.4s ease-in-out, box-shadow 0.4s ease-in-out, transform 0.1s ease-in-out;
}


.button-buy{
    width: 100%;
}

.button-installment,
.button-cart{
    gap: 10px;
    width: 240px;
}
.button-buy-title{
    display: flex;
    width: calc(100% - 70px);
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    z-index: 1;
}

.button-buy-icon{
    position: absolute;
    right:10px;
    display: flex;
    width: 60px;
    height: 60px;
    padding: 15px;
    border-radius: 50%;
    background-color: #ffffff;
    transition: background-color 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.double-button{
    display: flex;
    width: 100%;
    height: 80px;
    margin-bottom: 10px;
    justify-content: space-between;
}

.button-buy:hover .button-buy-icon {
    transform: scale(1.1);
}

.button-installment:hover .button-buy-icon {
    transform: scale(1.1);
}

.button-cart:hover .button-buy-icon {
    transform: scale(1.1);
}

.button-cart::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #3952e0;
    z-index: 0;
    transform: translateX(100%); 
    transition: transform 0.4s ease-in-out;
}

.button-cart:hover::before {
    transform: translateX(0);
}

.button-cart:hover {
    color: #ffffff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.button-cart:active {
    transform: scale(0.98);
    transition: 0.3s ease-in-out;
    box-shadow: 0px 0px 20px 0px #667bb3;
}


.button-installment::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #60ddeb;
    z-index: 0;
    transform: translateX(100%); 
    transition: transform 0.4s ease-in-out;
}

.button-installment:hover::before {
    transform: translateX(0);
}

.button-installment:hover {
    color: #ffffff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.button-installment:active {
    transform: scale(0.98);
    transition: 0.3s ease-in-out;
    box-shadow: 0px 0px 20px 0px #b3f1f8;
}

.button-buy::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #202c6e;
    z-index: 0;
    transform: translateX(100%); 
    transition: transform 0.4s ease-in-out;
}

.button-buy:hover::before {
    transform: translateX(0);
}

.button-buy:hover {
    color: #ffffff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.button-buy:active {
    transform: scale(0.98);
    transition: 0.3s ease-in-out;
    box-shadow: 0px 0px 20px 0px #667bb3;
}

.success-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #4CAF50;
    color: white;
    padding: 15px 25px;
    border-radius: 4px;
    z-index: 1000;
    animation: fadeInOut 2s ease-in-out;
}

.notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 15px 25px;
    border-radius: 10px;
    z-index: 1000;

    font-size: 1rem;
    font-weight: 300;

    animation: fadeInOut 2s ease-in-out;

    &.success {
        background-color: #4CAF50;
        color: white;
    }

    &.error {
        background-color: #f44336;
        color: white;
    }
}

@keyframes fadeInOut {
    0% { opacity: 0; transform: translateY(20px); }
    20% { opacity: 1; transform: translateY(0); }
    80% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(-20px); }
}