.wrapper {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

.avatar {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 12px;
}

.info {
    flex: 1;
    display: flex;
}

.info-tag{
    width: 70%;
    display: flex;
    flex-direction: column;
}

.name {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 4px;
}

.price {
    display: flex;
    gap: 8px;
}

.price-current {
    color: #24b12b;
    font-weight: 300;
    font-size: 1rem;
}

.price-discount{
    display: inline;
    font-size: 1.1rem;
    color: #000000;
}

.price-before{
    display: inline;
    color: #d70018;
    margin-left: 10px;
    font-size: 0.7rem;
    text-decoration: line-through;
}

.info-tag-right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    right: 0;
    width: 30%;
}