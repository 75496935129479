.wrapper {
    display: flex;
    flex-direction: column;
    width: 430px;
    user-select: none;
}

.title-gage {
    width: 100%;
    height: 150px;
    padding-top: 40px;
    display: flex;
    align-items: center;
    font-size: 35px;
    font-weight: 700;
    margin: 20px 0;
}


.tag {
    cursor: pointer;
    display: flex;
    align-items: center;
    box-shadow: 0 0 10px 0 rgb(180, 180, 180);
    width: 100%;
    height: 80px;
    margin: 15px 0;
    background-color: #f5f5f5;
    color: #000000;
    border-radius: 40px;
    position: relative;
    overflow: hidden;
    transition: color 0.4s ease-in-out, box-shadow 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.tag::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #999999;
    z-index: 0;
    transform: translateX(-100%); 
    transition: transform 0.4s ease-in-out;
}

.tag:hover::before {
    transform: translateX(0);
}

.tag:hover {
    color: #ffffff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.tag:active {
    transform: scale(0.98);
    transition: 0.3s ease-in-out;
    box-shadow: 0 0 5px rgba(150, 150, 150, 0.7);
}

.icon {
    z-index: 1;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: background-color 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.tag:hover .icon {
    transform: scale(1.1);
}

.title {
    z-index: 1;
    display: flex;
    margin-left: 15px;
    align-items: center;
    font-size: 21px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
}
