@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root{

    //default layout header
    --default-layout-header-height: 85px;
    --default-layout-header-width: 1400px;
    --color-primary: linear-gradient(to right, #101638, #313d7d, #40d5e5);
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:html{
    font-size: 62.5%;
}

body {
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    text-rendering: optimizeSpeed;
}

button, input, [tabindex]{
    outline: none;
    border: none;
}



::-webkit-scrollbar { 
    width: 12px;
}


::-webkit-scrollbar-track {
    border-radius: 20px;
    background: #f1f1f1;
}


::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.8);
    border: 3px solid #f1f1f1;
    border-radius: 20px;
    cursor: grab;
}


::-webkit-scrollbar-thumb:hover {
    background: rgba(68, 68, 68, 0.8);
}
