.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container{
    width: 100%;
    max-width: var(--default-layout-header-width);
    height: auto;
    display: flex;
}

.content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}