.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    user-select: none;
}

.inner {
    background-color: #fff;
    border-radius: 8px;
    min-width: 500px;
    width: auto;
    height: auto;
    overflow: hidden;
}