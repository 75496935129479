.wrapper {
    display: flex;
    height: auto;
    width: 100%;
    flex-direction: column;
    user-select: none;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

input[type="text"],
input[type="number"],
input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

input[type="file"] {
    padding: 5px;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

.add-case-body {
    display: flex;
    height: auto;
    width: 100%;
    flex-direction: column;
    user-select: none;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    h2 {
        margin-bottom: 20px;
        color: #333;
    }
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;

    label {
        font-weight: 500;
        color: #555;
    }
}

.error {
    color: #dc3545;
    font-size: 14px;
    margin-top: 5px;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	background-color: #f5f5f5;
	border-bottom: 1px solid #ddd;

	h2 {
		color: #333;
		margin: 0;
		font-size: 1.2rem;
	}
}

.close-btn {
	background: none;
	border: none;
	font-size: 24px;
	color: #000000;
	cursor: pointer;

	&:hover {
		box-shadow: #000000;
		background-color: #f5f5f5;
	}
}

.image {
	display: flex;
	width: 100%;
	height: 100px;
	background-size: auto;
	background-position: auto;
	justify-content: center;
	align-items: center;
}

.content {
	padding: 16px;
	height: auto;
	text-align: center;
	min-height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 1.2rem;
}