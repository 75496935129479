.wrapper {
    --width-search-btn: 60px;
    --search-button-width: 60px;
    --search-input-width: 600px;
    height: var(--default-layout-header-height);
    width: 100%;
    min-width: 100%;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 0.5);
    display: flex;
    justify-content: center;
}

.inner {
    width: var(--default-layout-header-width);
    height: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.img-logo{
    display: flex;
    width: 180px;
    max-width: auto;
    height: auto;
    cursor: pointer;
    user-select: none;
}

.search{
    user-select: none;
    position: relative;
    width: var(--search-input-width);
    height: 60px;
    display: flex;
    border-radius: 50px;
    background-color: rgb(230, 230, 230);
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    outline: none;

    input{
        color: #000000;
        font-size: 1.4rem;
        height: 100%;
        background-color: transparent;
        margin-left: 30px;
        flex: 1;
        border: none !important;
    }

    input:not(:placeholder-shown) ~ .search-btn{
        color: #000000;
        outline: none;
    }
    
    &::after{
        content: "";
        position: absolute;
        width: 2px;
        height: 30px;
        right: var(--width-search-btn);
        background-color: #888888;
        outline: none;
    }

    &:focus-within{
        border: 2px solid #aaaaaa;
    }
}

.clear{
    display: flex;
    position: absolute;
    right: calc(var(--search-button-width) - 5px);
    font-size: 1.4rem;
    color: #888888;
    background-color: transparent;

    cursor: pointer;

    &:hover{
        background-color: #ffffff00;
    }
}

.search-result{
    display: flex;
    width: 600px;
    margin-top: 10px;
}

.search-title{
    font-size: 1.2rem;
    font-weight: 500;
    padding: 5px;
    padding-left: 20px;
    color: #444444;
    border-bottom: 1px solid #e0e0e0;
    user-select: none;
}

.search-list{
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
}
.search-btn{
    width: var(--width-search-btn);
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: transparent;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;

    &:hover{
        background-color: #888888;
        transition: background-color 0.2s ease-in-out;
    }
}

.category-result{
    padding-top: 3px;
}

.icon-header{
    display: flex;
    height: auto;
    min-width: 500px;
    align-items: center;
    justify-content: space-between;
}

.category,
.icon-cart,
.icon-user{
    user-select: none;
    width: 150px;
    height: 60px;
    cursor: pointer;
    user-select: none;
    align-items: center;
    background-color: rgb(230, 230, 230);
    border-radius: 50px;
    display: flex;
    padding-left: 13px;
    transition: background-color 0.4s ease-in-out;

    &:hover{
        background-color: rgb(200, 200, 200);
        transition: background-color 0.4s ease-in-out;
    }
}

.header-title-button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 15px;
    color: #444444;
    font-weight: 700;
    user-select: none;
    margin-left: 10px;
}

.user-profile {
    height: 60px;
    width: auto;
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: rgb(230, 230, 230);
    padding: 8px 16px;
    border-radius: 50px;
    transition: background-color 0.4s ease-in-out;
    cursor: pointer;
    &:hover {
        background-color: rgb(200, 200, 200);
    }
}

.avatar-container {
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
}

.user-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.user-actions {
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.username {
    font-size: 14px;
    font-weight: 600;
    color: #444444;
    user-select: none;
}

.logout-btn {
    background: none;
    border: none;
    color: #666666;
    font-size: 13px;
    cursor: pointer;
    padding: 0;

    &:hover {
        background-color: rgb(200, 200, 200);
        color: #000000;
    }

    .logout-btn-title {
        font-weight: 600;
    }
}

.search-loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    
    span {
        margin-top: 10px;
        color: #666;
    }
}

.search-loading img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}


.search-no-results{
    padding: 16px;
    text-align: center;
    color: #666;
}

.search-results-title{
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 4px;
}

.search-no-results img{
    width: auto;
    height: 100px;
    object-fit: cover;
    margin-right: 12px;
}