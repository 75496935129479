.wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
    user-select: none;
    border-radius: 30px;
    transition: box-shadow 0.3s;
    text-decoration: none;
    
}

.wrapper:hover {
    border-radius: 30px;
    box-shadow: 0 0 15px #b4b4b4;
    transition: box-shadow 0.3s;
}

.img{
    display: flex;
    margin-top: 15px;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 180px;
    transform: scale(1);
    transition: transform 0.5s;
}
.img img{
    width: 150px;
    height: 150px;
}

.img:hover{
    transform: scale(1.03);
    transition: transform 0.5s;
}

.content{
    align-items: center;
    justify-content: center;
    height: 100px;
}

.inner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-left: 15px;
    padding-right: 15px;
}
.name{
    font-size: 1rem;
    font-weight: 400;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    padding-right: 15px;
    color: #000000;
}

.price-item{
    display: flex;
    font-size: 1.1rem;
    font-weight: 700;
    color: #000000;
}

.discount{
    font-size: 1.1rem;
    font-weight: 700;
}

.price{
    margin-left: 10px;
    font-size: 0.7rem;
    font-weight: 400;
    color: #ff0000;
    text-decoration: line-through;
}
.sale{
    font-size: 0.9rem;
    font-weight: 400;
    color: #00b309;
}