.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: var(--color-primary);
    user-select: none;
}

.form-box {
    position: relative;
    width: 400px;
    padding: 40px;
    height: auto;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title-form {
    text-align: center;
    color: white;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 30px;
}

.input-box {
    position: relative;
    margin: 30px 0;
    width: 100%;
    border-bottom: 2px solid white;
}

.title-input {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: 0.5s;
    color: white;
    font-size: 15px;
    font-weight: 500;
}

.input-box input:focus ~ label,
.input-box input:valid ~ label {
    top: -5px;
}

.input-box input {
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    color: white;
    font-size: 15px;
    font-weight: 300;
    padding: 0 35px 0 5px;
}

.icon-form {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-size: 1.2em;
}

.rememder-input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.remember-title{
    margin-left: 10px;
    color: white;
    font-size: 15px;
    font-weight: 500;
}

.button-submit {
    display: flex;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: #0f163d;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    transition: 0.3s;

    &:hover {
        transition: 0.3s;
        background: #20b1f5;
        color: #0f163d;
    }

    &:active {
        scale: 0.98;
    }
}

.error-message{
    color: red;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
}

.loading-image {
    display: block;
    margin: 20px auto;
    width: 50px;
    height: 50px;
    color: white;
}