.wrapper {
    display: flex;
    height: 100vh;
    width: 100%;
    background: var(--color-primary);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); 
        z-index: 1;
    }
}

.image {
    position: relative;
    z-index: 2;

    img {
        width: 30%;
        height: auto;
        display: block;
        margin: 0 auto;
    }
}

.inner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
}

.loading-circles {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    position: relative;
    z-index: 2;

    .circle {
        width: 10px;
        height: 10px;
        margin: 0 5px;
        background-color: white;
        border-radius: 50%;
        animation: wave 1s infinite ease-in-out;
    }

    .circle:nth-child(1) {
        animation-delay: 0s;
    }

    .circle:nth-child(2) {
        animation-delay: 0.1s;
    }

    .circle:nth-child(3) {
        animation-delay: 0.2s;
    }

    .circle:nth-child(4) {
        animation-delay: 0.3s;
    }

    .circle:nth-child(5) {
        animation-delay: 0.4s;
    }

    .circle:nth-child(6) {
        animation-delay: 0.5s;
    }

    .circle:nth-child(7) {
        animation-delay: 0.6s;
    }
}

@keyframes wave {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px);
    }
}