.wrapper {
	height: 200px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 30px;
	background-color: rgb(255, 255, 255);
	box-shadow: 0 0 10px 0 rgb(190, 190, 190);
	margin-bottom: 20px;

	&:hover {
		.delete-btn {
			opacity: 1;
			visibility: visible;
		}
	}
}

.container {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
.content {
	width: 92%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.product {
	display: flex;
	width: 50%;
	height: 100%;
	align-items: center;
	justify-content: center;

	@media (max-width: 768px) {
		width: 100%;
	}
}

.image {
	width: 130px;
	height: 130px;
	display: flex;
}
.info {
	padding-left: 15px;
	width: 72%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.name {
	width: auto;
	height: auto;
	font-size: 1.1rem;
	font-weight: 700;
	color: #4d4d4d;
	word-wrap: break-word;
}

.description {
	width: 340px;
	height: auto;
	font-size: 0.9rem;
	color: #4d4d4d;
	white-space: normal;
	overflow: visible;
}

.quantity-controls {
	display: flex;
	align-items: center;
	height: 30px;
	width: auto;

	button {
		display: flex;
		background-color: #888888;
		color: white;
		border: none;
		height: 30px;
		width: 20px;
		border-radius: 8px;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		transition: background-color 0.3s ease, transform 0.2s; /* Hiệu ứng chuyển động */

		&:hover {
			background-color: #0056b3;
			box-shadow: 0 0 5px #0f49dd;
			transform: scale(1.05);
			transition: 0.3s;
		}

		&:active {
			transform: scale(0.95);
			box-shadow: 0 0 5px #0f49dd;
			transition: 0.3s;
		}
	}

	input[type="number"] {
		-moz-appearance: textfield;
		appearance: none;
		width: 40px;
		height: 30px;
		font-size: 16px;
		text-align: center;
		border: 2px solid #ccc;
		border-radius: 8px;
		outline: none;
		margin: 0 5px;
		transition: 0.3s;

		&:hover {
			border-color: #0f49dd;
			box-shadow: 0 0 5px #0f49dd;
			transition: 0.3s;
		}

		&:focus {
			border-color: #0f49dd;
			box-shadow: 0 0 10px #0f49dd;
			transition: 0.3s;
		}

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			display: none;
		}
	}
}

.price {
	width: 100px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
}
.total {
	width: 100px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
}

.delete-btn {
	right: 0;
	top: 0;
	height: 30px;
	width: 30px;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	font-size: 20px;
	color: #666;
	box-shadow: 0 0 5px #000000;
	transition: 0.3s;
	opacity: 0;
	visibility: hidden;

	&:hover {
		transition: 0.3s;
		scale: 1.1;
	}
}
