.wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 91vh;
    width: var(--default-layout-header-width);
    user-select: none;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    height: auto;
    width: 650px;
}

.image img {
    display: inline;
    height: 100%;
    width: 100%;
    filter: drop-shadow(0 10px 20px rgb(90, 90, 90));
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    height: 750px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(180, 180, 180, 0.75);
    background-color: #fff;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.content {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.label {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
}

.input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border 0.3s;
}

.input:focus {
    border: 1px solid #007bff;
    outline: none;
}

textarea.input {
    height: 100px;
    overflow-y: auto; /* Hiện thanh cuộn dọc khi cần */
    resize: none; /* Không cho phép người dùng thay đổi kích thước */
}

.dob-container {
    display: flex;
    justify-content: space-between;
}

.dob-input {
    width: 30%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.submit-btn {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.submit-btn:hover {
    background-color: #0056b3;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
.submit-btn:active {
    background-color: #3b7fc7;
    box-shadow: none;
}