.wrapper {
    display: flex;
    min-height: 850px;
    height: auto;
    width: var(--default-layout-header-width);
    flex-direction: column;
}

.banner-body {
    margin-top: 40px;
    display: flex;
    width: 100%;
    height: auto;
}

.brand-choice {
    margin-top: 30px;
    display: flex;
    width: 100%;
    height: auto;
}

.inner {
    display: flex;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 30px;
    justify-content: space-between;
}

.sidebar {
    width: 25%;
    height: auto;
}

.content {
    display: flex;
    flex-direction: column;
    width: 72%;
    height: auto;
}