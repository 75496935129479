.wrapper{
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
}

.inner{ 
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    user-select: none;
}
.header{
    display: flex;
    height: 50px;
    font-weight: 700;
    align-items: center;
}

.header-icon{
    width: 35px;
    height: auto;
}
.header-title{
    margin-left: 5px;
    align-items: center;
}

.content-title{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.price-options{
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.price-options-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.title-options{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 10px;
}

.price-options-content select{
    padding-bottom: 10px;
    flex: 1;
    margin: 0 10px;
    text-align: center;
    text-align-last: center;
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    transition: all 0.3s ease;
    appearance: none;
    background-color: #fff;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
    background-position: calc(100% - 15px) center; 
    padding-right: 40px;
}

.price-options-content select:focus {
    border-color: #0f49dd;
    box-shadow: 0 0 5px #0f49dd;
    outline: none;
}

.price-options-contentselect:hover {
    border-color: #0f49dd;
    box-shadow: 0 0 5px #0f49dd;
}

.button-apply {
    background-color: #f5f5f5;
    box-shadow: 0 0 10px 0 rgb(80, 80, 80);
    border-radius: 30px;
    display: flex;
    width: auto;
    height: 60px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #002ba0;

    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    transition: 0.5s;
    cursor: pointer;
}

.button-apply:hover {
    background-color: #0f49dd;
    transition: 0.5s;
}

.button-apply:active {
    background-color: #0f49dd;
    transform: scale(0.95);
    transition: 0.1s; 
}
