.wrapper{
    display: flex;
    height: 100vh;
    width: 100%;
    user-select: none;
}

.sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 20%;
    background-color: rgb(230, 230, 230);
    z-index: 1;
}

.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
}

.logo img {
    width: 200px;
    height: auto;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.3));
}

.item-sidebar {
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;
    transition: all 0.3s ease;

    &:hover {
        background-color: rgba(255, 255, 255);
    }

    &.active {
        background-color: rgba(255, 255, 255);
        color: #000000;
        font-weight: bold;
    }
}

.menu{
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    color: #686868;
}

.inner{
    width: 80%;
    flex: 1;
    height: auto;
    background-color: rgb(255, 255, 255);
}