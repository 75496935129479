.wrapper {
    width: var(--default-layout-header-width);
    display: flex;
    justify-content: center;
    user-select: none;
    height: auto;
}

.container{
    width: 100%;
    height: auto;
    display: flex;
    min-height: 91vh;
}
.temp{
    width: 100%;
    height: auto;
    flex-direction: column;
}

.inner{
    width: auto;
    background-color: #f5f5f5;
    height: auto;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    margin-top: 50px;
    margin-bottom: 30px;
    box-shadow: 0 0 10px 0 rgb(190, 190, 190);
    border-radius: 30px;
}

.empty-cart{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    font-size: 1.5rem;
    font-weight: 300;
    color: #4d4d4d;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    font-size: 16px;
    color: #666;
    border: 1px dashed #ccc;
    border-radius: 8px;
    margin: 20px 0;
}

.header{
    width: 80%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    justify-content: space-between;
}
.name,
.title{
    font-size: 1.1rem;
    font-weight: 700;
    color: #4d4d4d;
}

.title{
    margin-right: 50px;
}
.name{
    margin-right: 350px;
}

.content{
    height: auto;
    width: 90%;
    margin-bottom: 30px;
}

.checkout-temp{
    background-color: #f5f5f5;
    box-shadow: 0 0 10px 0 rgb(190, 190, 190);
    margin-left: 30px;
    border-radius: 30px;
    display: flex;
    margin-bottom: 30px;
    width: auto;
    height: 120px;
    align-items: center;
    justify-content: space-between;
}

.total{
    margin-left: 50px;
}

.title-total{
    font-size: 1.1rem;
    font-weight: 700;
    color: #4d4d4d;
    margin-bottom: 5px;
}
.checkout{
    background-color: #f5f5f5;
    box-shadow: 0 0 10px 0 rgb(80, 80, 80);
    margin-right: 50px;
    border-radius: 30px;
    display: flex;
    width: auto;
    height: 60px;
    width: 150px;
    align-items: center;
    justify-content: center;
    background-color: #002ba0;

    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    transition: 0.5s;
    cursor: pointer;
}

.checkout:hover{
    background-color: #0f49dd;
    transition: 0.5s;
    opacity: 0.9;
}

.checkout:active {
    transform: scale(0.98);
}

select{
    width: 100%;
    padding: 15px;
    font-size: 18px;
    border-radius: 30px;
    cursor: pointer;
    border: 1px solid #ccc;
}

.method-payment select {
    padding-bottom: 10px;
    flex: 1;
    margin: 0 10px;
    text-align: center;
    text-align-last: center;
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    transition: all 0.3s ease;
    appearance: none;
    background-color: #fff;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
    background-position: calc(100% - 15px) center; 
    padding-right: 40px;
}

.method-payment select:focus {
    border-color: #0f49dd;
    box-shadow: 0 0 5px #0f49dd;
    outline: none;
}

.method-payment select:hover {
    border-color: #0f49dd;
    box-shadow: 0 0 5px #0f49dd;
}

.header-popup {
    width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	background-color: #f5f5f5;
	border-bottom: 1px solid #ddd;

	h2 {
		color: #333;
		margin: 0;
		font-size: 1.2rem;
	}
}

.close-btn-popup {
	background: none;
	border: none;
	font-size: 24px;
	color: #000000;
	cursor: pointer;

	&:hover {
		box-shadow: #000000;
		background-color: #f5f5f5;
	}
}

.image {
	display: flex;
	width: 100%;
	height: 100px;
	background-size: auto;
	background-position: auto;
	justify-content: center;
	align-items: center;
}

.pic {
	width: 100px;
	height: 100%;
}

.content-popup {
    width: 100%;
	padding: 16px;
	height: auto;
	text-align: center;
	min-height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 1.2rem;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 16px;
    color: #666;
}