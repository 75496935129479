.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-primary);
    height: auto;
    width: 100%;
    border-top: 2px solid #e0e0e0;
    color: #ffffff;
    overflow: hidden;
    flex-direction: column;
}

.container{
    border-top: 2px solid #858585;
    margin-top: 50px;
    display: flex;
    width: 1400px;
    height: auto;
    user-select: none;
    justify-content: space-between;
}

.inner{
    margin-top: 50px;
}
.title{
    font-size: 24px;
    font-weight: 700;
    margin-right: 20px;
}

.content-icon{
    width: 250px;
    margin-top: 15px;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
}

.content{
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    left: 0;
}

.content-item{
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
.content-item:hover{
    text-decoration: underline;
}

.technology-container{
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 50px;
    flex-direction: column;
    text-align: center;
}

.technology-container-title{
    font-size: 24px;
    font-weight: 700;    
    width: 100%;
    margin-right: 20px;
    margin-bottom: 20px;
}
.technology-content{
    display: block;
    margin-top: 10px;
    height: 100px;
    width: 100%;
    margin-bottom: 20px;
}

.technology-icon-list{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    flex-direction: column;
}

.technology-icon{
    display: inline;
    margin: 20px; 
}



.container-end{
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 150px;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #858585;
}

.container-end{
    user-select: none;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
}

.about-us{
    width: 100%;
    height: 100px;
    display: flex;
    margin-top: 40px;
    margin-left: 25px;
}
.button{
    width: 200px;
    height: 100px;
    background: #002ba0;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 25px;
    font-weight: 900;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: background 0.5s;
}
.button:hover{
    background: #245acf;
    transition: background 0.5s;
    color: #a8bfff;
}