.wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.banner-body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    &:hover .control-button {
        opacity: 1; 
        transition: opacity 0.3s ease-in-out; 
    }
}

.banner-container {
    display: flex;
    gap: 17px; 
    transition: transform 0.5s ease-in-out;
    width: calc(100% * 3); 
    padding: 0 10px;
    height: 100%;
}

.image-banner {
    width: calc((100% / 3) - 20px);
    height: 100%;
    border-radius: 30px;
    object-fit: cover;
}

.controls {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.control-button {
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 10px rgb(80, 80, 80);
    border: none;
    background-color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 20px;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease; 

    .banner-body:hover & {
        opacity: 1;
        pointer-events: auto;
        transition: opacity 0.5s ease, transform 0.5s ease; 
    }

    &:hover {
        transform: scale(1.1); 
        background-color: #fff;
        transition: transform 0.5s ease; 
    }

    &:active {
        transform: scale(0.9); 
        transition: transform 0.5s ease; 
    }
}
