.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    width: 100%;
    height: auto;
    user-select: none;
}

.inner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 18px;
    width: 100%;
    height: auto;
}

.no-products{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.err-title{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    font-size: 1.5rem;
    font-weight: 300;
    color: #4d4d4d;
}

.err-img{
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

.product {
    display: flex;
    overflow: hidden;
    background-color: #f0f0f0;
    border-radius: 20px;
    height: 305px;
    width: 235px;
    user-select: none;
    cursor: pointer;
    transition: box-shadow 0.3s;

    &:hover {
        box-shadow: 0 0 15px #b4b4b4;
        transition: box-shadow 0.3s;
    }
}

.show-more {
    display: flex;
    margin-top: 30px;
    padding: 20px;
    width: 300px;
    border-radius: 50px;
    background-color: #002ba0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0059b8;
    }
}


.show-more-content{
    font-size: 1.3rem;
    color: #ffffff;
    margin-right: 10px;
    font-weight: 400;
}

.show-more:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}